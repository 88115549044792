<template>
  <div>
    <Card>
      <template #header>Ürün Kullanım Bilgileri</template>
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card class="card card-custom gutter-b">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </b-card>
        </template>
        <div class="row mb-5">
          <div class="col-12">
            <h4 class="pb-3 border-secondary text-secondary border-bottom">Kod Kullanımları</h4>
            <BarChart :labels="values.codeValues.map(item => item.name)"
                      :series="codeSeries" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-5">
            <h4 class="pb-3 border-secondary text-secondary border-bottom">Tarayıcı Kullanımları</h4>
            <PieChart v-if="values && values.browserValues && values.browserValues.length > 0"
                      :labels="values.browserValues.map(item => item.name)"
                      :series="values.browserValues.map(item => item.data)" />
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-5">
            <h4 class="pb-3 border-secondary text-secondary border-bottom">Cihaz Kullanımları</h4>
            <PieChart v-if="values && values.deviceValues && values.deviceValues.length > 0"
                      :labels="values.deviceValues.map(item => item.name)"
                      :series="values.deviceValues.map(item => item.data)" />
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-5">
            <h4 class="pb-3 border-secondary text-secondary border-bottom">Ülke Kullanımları</h4>
            <PieChart v-if="values && values.countryValues && values.countryValues.length > 0"
                      :labels="values.countryValues.map(item => item.name)"
                      :series="values.countryValues.map(item => item.data)" />
          </div>

          <div class="col-12 col-md-6 col-lg-4 mb-5">
            <h4 class="pb-3 border-secondary text-secondary border-bottom">Şehir Kullanımları</h4>
            <PieChart v-if="values && values.cityValues && values.cityValues.length > 0"
                      :labels="values.cityValues.map(item => item.name)"
                      :series="values.cityValues.map(item => item.data)" />
          </div>
        </div>
      </b-skeleton-wrapper>
    </Card>
  </div>
</template>

<script>
export default {
  name: "companyProductReportChart",
  data() {
    return {
      loading: false,
      values: [],
      codeSeries: [
        {
          data: [],
        },
      ],
    };
  },
  created() {
    this.getValues();
  },
  methods: {
    getValues() {
      this.loading = true;

      this.values = [];
      this.$dbFunctions
        .get(
          `${this.$route.params.companyId}/companyProduct/${this.$route.params.productId}/report/values`
        )
        .then((res) => {
          if (res.success == true) {
            this.values = res.data;
            this.setCodeChartValues();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setCodeChartValues() {
      if (this.values.codeValues && this.values.codeValues.length > 0) {
        let _series = [];

        //Begin::Code
        this.codeSeries = [];
        this.values.codeValues.forEach((element) => {
          _series.push(element.data);
        });

        this.codeSeries = [{ data: _series }];
        //End::Code
      }
    },
  },
};
</script>
